import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import logo from './Conference Sponsorship logo.png'
import MenuIcon from '@mui/icons-material/Menu'

export function Header() {

	return(
		
		<Grid container sx={{display: 'flex', justifyContent: 'center'}}>
		<Grid item xs={12} md={10}>
      <header style={{padding: '30px'}}>
	  <Box sx={{backgroundColor: 'white'}}>
		<Grid container>
		{/*
		<Grid item xs={4} sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center'}}>
		<MenuIcon color='primary' sx={{marginRight: '10px'}} />
	  <Typography sx={{color: 'blue'}} variant={'h7'}>Menu</Typography>
		</Grid>*/}
		<Grid item xs={6} sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center'}}>
		<a href='/'>
		<img src={logo} width={50}/>
		</a>
		</Grid>
		<Grid item xs={6} sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', alignItems: 'center'}}>
		<Button onClick={() => document.getElementById('signup').scrollIntoView({behavior: 'smooth'})} size='large' variant='outlined' sx={{borderRadius: '35px', minHeight: '60px', padding: '5px 25px', textTransform: 'none'}}>Join now</Button>
		</Grid>
		</Grid>
	  </Box>

      </header>
		</Grid>
		</Grid>
	)}
