import { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';


export function SignInForm({db}) {

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const [signInRequested, setSignInRequested ] = useState(false)

	function userSignIn(data) { 
		
	}

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
	userSignIn(data);
    /*console.log({
      email: data.get('email'),
      password: data.get('password'),
    });*/
  };	

	if(!signInRequested) {
	return(
		        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
		paddingLeft: '20px',
			  paddingRight: '20px'
          }}
        >
		<Button size='large' type="submit" fullWidth variant='outlined' onClick={() => setSignInRequested(true)} sx={{mt: 3, mb: 0, borderRadius: '35px', minHeight: '70px', maxWidth: '486px', padding: '5px 25px', textTransform: 'none', border: '1px solid black'}}><Typography variant='h5'>Sign in</Typography></Button>
        </Box>
	)}
	else return(
		        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
		paddingLeft: '20px',
			  paddingRight: '20px'
          }}
        >
          <Typography variant="h4" sx={{mb: 1, color: '#638bb7', textAlign: 'left', marginRight: '20px', fontWeight: '300'}}>
           Sign In 
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
		type='email'
		value={email}
		sx={{border: 'solid 1px #638bb7'}}
		onChange={e=> setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
		value={password}
		sx={{border: 'solid 1px #638bb7'}}
		onChange={e=> setPassword(e.target.value)}
                />
              </Grid>
            </Grid>
		<Button size='large' type="submit" fullWidth variant='contained' sx={{mt: 3, mb: 2, borderRadius: '35px', minHeight: '70px', padding: '5px 25px', textTransform: 'none'}}><Typography variant='h5'>Sign In</Typography></Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#" variant="body2">
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
	)}
