import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth'
import { collection, addDoc } from 'firebase/firestore'


export function SignUpForm({db}) {

	const auth = getAuth()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [fullName, setFullName] = useState('')
	const [companyName, setCompanyName] = useState('')
	const [position, setPosition] = useState('')
	const [user, setUser] = useState(null)
	const [userChangedFlag, setUserChangedFlag] = useState(false)
	const [userDetailsDocRef, setUserDetailsDocRef] = useState(null)

	useEffect(() => onAuthStateChanged(auth, (user) => {
		if (user) { setUser(user) }
	}), [userChangedFlag])


	function userCreator() { createUserWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
			//Signed in
			const user = userCredential.user
			setUserChangedFlag(true)
		})
		.catch((error) => {
			const errorCode = error.code
			const errorMessage = error.message
		})
	}

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
	userCreator();
    /*console.log({
      email: data.get('email'),
      password: data.get('password'),
    });*/
  };	
	async function setUserDetails() {
		const docRef = await addDoc(collection(db, "users"), {
			uid: user.uid,
			name: fullName,
			company: companyName,
			position: position
		})
		setUserDetailsDocRef(docRef)
	
	}
	
	const handleUserDetailsSubmit = e => {
		e.preventDefault();
		setUserDetails()
		/* write to db */
		
	}

	if(!user) {
	return(
		        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
		paddingLeft: '20px',
			  paddingRight: '20px'
          }}
        >
		<Grid container>
		<Grid item xs={0} md = {3}></Grid>
		<Grid item xs={12} md={6}>
          <Typography variant="h4" sx={{mb: 1, color: '#638bb7', textAlign: 'left', marginRight: '20px', fontWeight: '300', textAlign: 'center', marginTop: '30px', lineHeight: '1.5em'}}>
           Reach everyone from Bank CEOs to AI Researchers through targeted conference sponsorship
          </Typography>
		<Grid item xs={0} md = {3}></Grid>
		</Grid>
		</Grid>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
		type='email'
		value={email}
		sx={{border: 'solid 1px #638bb7'}}
		onChange={e=> setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
		value={password}
		sx={{border: 'solid 1px #638bb7'}}
		onChange={e=> setPassword(e.target.value)}
                />
              </Grid>
            </Grid>
		<Typography variant='body2' sx={{marginTop: '20px', color: 'gray'}}>Please use your company email address for our team to verify your account.</Typography>
		<Button size='large' type="submit" fullWidth variant='contained' sx={{mt: 3, mb: 2, borderRadius: '35px', minHeight: '70px', padding: '5px 25px', textTransform: 'none'}}><Typography variant='h5'>Join</Typography></Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#" variant="body2">
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
	)}
	else if (!userDetailsDocRef) { return(
		        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
		paddingLeft: '20px',
			  paddingRight: '20px'
          }}
        >
          <Typography variant="h4" sx={{mb: 1, color: 'purple', textAlign: 'left', marginRight: '20px', fontWeight: '300'}}>
            Find the best conferences to sponsor
          </Typography>
          <Box component="form" noValidate onSubmit={handleUserDetailsSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="fullName"
                  label="Full Name"
                  name="fullName"
                  autoComplete="Full Name"
		type='text'
		value={fullName}
		sx={{border: 'solid 1px #638bb7'}}
		onChange={e=> setFullName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="Company Name"
                  label="Company Name"
                  type="text"
                  id="companyName"
                  autoComplete="company-name"
		value={companyName}
		sx={{border: 'solid 1px #638bb7'}}
		onChange={e=> setCompanyName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="Position"
                  label="Position"
                  type="text"
                  id="position"
                  autoComplete="position"
		value={position}
		sx={{border: 'solid 1px #638bb7'}}
		onChange={e=> setPosition(e.target.value)}
                />
              </Grid>
            </Grid>
		<Button size='large' type="submit" fullWidth variant='contained' sx={{mt: 3, mb: 2, borderRadius: '35px', minHeight: '70px', padding: '5px 25px', textTransform: 'none'}}><Typography variant='h5'>Join</Typography></Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#" variant="body2">
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
	)}	
	else return(

		        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
		paddingLeft: '20px',
			  paddingRight: '20px'
          }}
        >
          <Typography variant="h4" sx={{mb: 1, color: 'purple', textAlign: 'left', marginRight: '20px', fontWeight: '300'}}>
            Find the best conferences to sponsor
          </Typography>
          <Typography variant="h4" sx={{mb: 1, color: 'purple', textAlign: 'left', marginRight: '20px'}}>
		Thank you for signing up. We will review your registration and assign you the correct permissions shortly.</Typography>
        </Box>

	)}

