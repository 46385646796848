import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'


export function RegisterForFree() {

	return(
		<Box sx={{padding: '80px 20px', backgroundColor: '#f5f5f5', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start'}}>
		<Grid container sx={{display: 'flex', justifyContent: 'center'}}>
		<Grid container item xs={0} md={2}></Grid>
		<Grid container item xs={12} md={8} sx={{justifyContent: 'center'}}>
		<Typography variant='h4' sx={{marginBottom: '25px', textAlign: 'left'}}>Reach exactly the right people with Conference Sponsorship</Typography>
		<div style={{flexBasis: '100%', height: '0'}}></div>
		<Button onClick={() => document.getElementById('signup').scrollIntoView({behavior: 'smooth'})} variant='contained' size='large' sx={{borderRadius: '35px', mt: 5, padding: '20px 35px', textTransform: 'none', textAlign: 'center'}}>Get started</Button>
		</Grid>
		<Grid container item xs={0} md={2}></Grid>
		</Grid>
		</Box>

	)
}
