import './App.css';
import {Header} from './Header'
import {Footer} from './Footer'
import {Home} from './Home'
import { SiteTerms } from './SiteTerms'
import { Privacy } from './Privacy'
import { Routes, Route } from 'react-router-dom'
import { db } from './firebaseDbProvider'


function App() {

  return (
    <div className="App">
	  <Header />
	  <Routes>
	  {/* <Route path="/" element={<Home db={db} conferencesList={conferencesList} setTargetAudience={setTargetAudience} targetAudience={targetAudience} />} />*/}
	  <Route path="/" element={<Home db={db} />} />
	  <Route path='/site-terms' element={<SiteTerms />} />
	<Route path='privacy' element={<Privacy />} />
	  </Routes>
	  <Footer />
    </div>
  );
}



export default App;
