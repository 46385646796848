import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { useEffect, useState } from 'react'
import { collection, getDocs, limit, query, where } from 'firebase/firestore'


/*export function FindConferencesByAudience({conferencesList, audienceSelectionFunction, targetAudience}) {
*/
export function FindConferencesByAudience({db}) {


	const [conferencesList, setConferencesList] = useState([])
	const [targetAudience, setTargetAudience] = useState('AI')

	useEffect(() => {
		async function getConferences() {
			/*const conferencesCollection = collection(db, 'conferences')*/
			const conferencesRef = collection(db, 'conferences')
			/*const conferencesCollection = query(conferencesRef, where("Topic", "==", "AI"), orderBy('Title'), limit(3));*/
			const conferencesCollection = query(conferencesRef, where("Topic", "==", targetAudience), limit(3));
			const conferencesSnapshot = await getDocs(conferencesCollection)
			const conferencesList = conferencesSnapshot.docs.map(doc => {return { ...doc.data(), 'id': doc.id}})
			setConferencesList(conferencesList)
		}
		getConferences()
	}, [targetAudience])



	function audienceSelectClick(audience) {
		/*
		audienceSelectionFunction(audience)
		*/
		setTargetAudience(audience)
	}


	return(
	  		<Grid container sx={{backgroundColor: '#f5f5f5', margin: '30px 0', padding: '10px 20px', justifyContent: 'center'}}>

		<Grid container item xs={0} md={2}></Grid>
		<Grid container item xs={12} md={8}>
		<Typography variant='h4' sx={{margin: '75px 0 45px 0', textAlign: 'left'}}>Find conferences with precisely the right attendees</Typography>
	<div>
	<Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start'}}>	
	  <Button onClick={() => document.getElementById('signup').scrollIntoView({behavior: 'smooth'})} avariant='outlined' size='large' sx={{borderRadius: '35px', margin: '5px', padding: '20px 25px', border: 'solid 2px', textTransform: 'none'}}>Register to see all audiences</Button>
	  <Button onClick={() => audienceSelectClick('Data Science')} variant= {targetAudience === 'Data Science' ? 'contained' : 'outlined' } size='large' sx={{borderRadius: '35px', margin: '5px', padding: '20px 25px', textTransform: 'none'}}>Data Scientists</Button>
	  <Button onClick={() => audienceSelectClick('Fintech')} variant= {targetAudience === 'Fintech' ? 'contained' : 'outlined' } size='large' sx={{borderRadius: '35px', margin: '5px', padding: '20px 25px', textTransform: 'none'}}>Fintech Management</Button>
	  <Button onClick={() => {audienceSelectClick('Technology')}} variant= {targetAudience === 'Technology' ? 'contained' : 'outlined'} size='large' sx={{borderRadius: '35px', margin: '5px', padding: '20px 25px', textTransform: 'none'}}>CTOs</Button>
	  <Button onClick = {() => {audienceSelectClick('AI')}} variant={targetAudience === 'AI' ? 'contained' : 'outlined'} size='large' sx={{borderRadius: '35px', margin: '5px', padding: '20px 25px', textTransform: 'none'}}>AI Researchers</Button>
	  <Button onClick = {() => {audienceSelectClick('Finance')}} variant={targetAudience === 'Finance' ? 'contained' : 'outlined'} size='large' sx={{borderRadius: '35px', margin: '5px', padding: '20px 25px', textTransform: 'none'}}>Portfolio Managers</Button>

	</Box>
		</div>




				<Grid container rowSpacing={3} sx={{mt: 3, mb: 8}}>	  			
	  			<Grid container item><Grid item xs={3}><h4>Title</h4></Grid><Grid item xs={2}><h4>Topic</h4></Grid><Grid item xs={4}><h4>Dates</h4></Grid><Grid item xs={3}><h4>Country</h4></Grid></Grid>
	  			{ conferencesList.length > 0 ? conferencesList.map(conferencesObj => <Grid container item key={conferencesObj.id}><Grid item xs={3}>{conferencesObj.Title}</Grid><Grid item xs={2}>{conferencesObj.Topic}</Grid><Grid item xs={4} sx={{padding: '0 25px'}}>{conferencesObj.Dates}</Grid><Grid item xs={3}>{conferencesObj.Country}</Grid></Grid>) : '' }
	  			<Grid container item sx={{filter: 'blur(5px)'}}><Grid item xs={3}>Register to unlock</Grid><Grid item xs={2}>AI</Grid><Grid item xs={4}>June 1-25, 2022</Grid><Grid item xs={3}>United States</Grid></Grid>
	  			<Grid container item sx={{filter: 'blur(5px)'}}><Grid item xs={3}>Register to unlock</Grid><Grid item xs={2}>AI</Grid><Grid item xs={4}>June 1-25, 2022</Grid><Grid item xs={3}>United States</Grid></Grid>
	  </Grid>
		<Button onClick={() => document.getElementById('signup').scrollIntoView({behavior: 'smooth'})} variant='outlined' size='large' sx={{borderRadius: '35px', padding: '20px 35px', marginTop: '30px', marginBottom: '30px', textTransform: 'none'}}>Register for free to access data as a verified sponsor</Button>
		</Grid>
		<Grid container item xs={0} md={2}></Grid>
			</Grid>
	)
	
}
