import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import {SignUpForm} from './SignUpForm'
import {SignInForm} from './SignInForm'
import {RegisterForFree} from './RegisterForFree'
import {FindConferencesByAudience} from './FindConferencesByAudience'
import {FindConferencesByOtherCriteria} from './FindConferencesByOtherCriteria'



export function Home({ db } ) {




return(
	  <section>
		<Grid container sx={{mt: 0}}>
			<Grid item xs={0} md={1}></Grid>
			<Grid item xs={12}>
	  			<div id="signup">
	  			<SignUpForm db={db} />
	  			</div>
	  			<Divider sx={{margin: '20px 0', paddingLeft: '20px', paddingRight: '20px'}}>Already on Conference Sponsorship?</Divider>
	<SignInForm />
	<FindConferencesByAudience db={db} />
	<FindConferencesByOtherCriteria />
	<RegisterForFree />
	  		</Grid>
			<Grid item xs={0} md={1}></Grid>
		</Grid>
	  </section>



)




}
