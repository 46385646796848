import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'


export function FindConferencesByOtherCriteria(){

	return(
		<Box sx={{margin: '65px 20px 45px 20px'}}>
		<Grid container sx={{display: 'flex', justifyContent: 'center'}}>
		<Grid container item xs={0} md={2}></Grid>
		<Grid container item xs={12} md={8}>
		<Typography variant='h4' sx={{margin: '25px 0 45px 0', textAlign: 'left'}}>
		Find conferences by specific criteria from sponsorship cost to previous sponsors</Typography>
		<Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start'}}>
	  <Button variant='outlined' size='large' sx={{borderRadius: '35px', margin: '5px', border: 'solid 2px', padding: '20px 25px', textTransform: 'none'}}>See all filters</Button>
	  <Button variant='outlined' size='large' sx={{borderRadius: '35px', margin: '5px', padding: '20px 25px', textTransform: 'none'}}>Sponsorship cost</Button>
	  <Button variant='outlined' size='large' sx={{borderRadius: '35px', margin: '5px', padding: '20px 25px', textTransform: 'none'}}>Location</Button>
	  <Button variant='outlined' size='large' sx={{borderRadius: '35px', margin: '5px', padding: '20px 25px', textTransform: 'none'}}>Number of attendees</Button>
	  <Button variant='outlined' size='large' sx={{borderRadius: '35px', margin: '5px', padding: '20px 25px', textTransform: 'none'}}>Sponsorship cost per attendee</Button>
	  <Button variant='outlined' size='large' sx={{borderRadius: '35px', margin: '5px', padding: '20px 25px', textTransform: 'none'}}>Previous sponsors</Button>
		<Button onClick={() => document.getElementById('signup').scrollIntoView({behavior: 'smooth'})} variant='outlined' size='large' sx={{borderRadius: '35px', padding: '20px 35px', mt: 8, mb: 6, textTransform: 'none'}}>Register for free to access data as a verified sponsor</Button>
		</Box>
		</Grid>
		<Grid container item xs={0} md={2}></Grid>
		</Grid>
		</Box>
	)}
