import {initializeApp} from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyAdW43aFgmi3XjEHwjRGkb988w0lkeDMyc",
  authDomain: "conference-sponsorship.firebaseapp.com",
  projectId: "conference-sponsorship",
  storageBucket: "conference-sponsorship.appspot.com",
  messagingSenderId: "788619753639",
  appId: "1:788619753639:web:07527bae167dcc6f2b0382"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)

