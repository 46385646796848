import logo from './Conference Sponsorship logo.png'
import Grid from '@mui/material/Grid'
import { Link } from 'react-router-dom'

export function Footer(){
	return(
		<Grid container sx={{display: 'flex', justifyContent: 'center'}}>
		<Grid item xs={12} md={10}>
	  <footer style={{padding: '30px 20px', textAlign: 'left'}}>
	<img src={logo} width={40} />	

	<p>Copyright 2022</p>
		<p><Link reloadDocument to='/' style={{textDecoration: 'none'}}>Home</Link></p>
		<p><Link reloadDocument to='/site-terms' style={{textDecoration: 'none'}}>Site Terms</Link></p>
		<p><Link reloadDocument to='/privacy' style={{textDecoration: 'none'}}>Privacy Policy</Link></p>
	  </footer>
		</Grid>
		</Grid>

	)}
